export const roles = [{
  'code': 'worker',
  'name': '员工',
  'dataAuthorityConfig': 'worker',
  'type': 'norm'
},
{
  'code': 'dean',
  'name': '院长/副院长',
  'dataAuthorityConfig': 'org',
  'type': 'norm'
},
{
  'code': 'finance',
  'name': '财务',
  'dataAuthorityConfig': 'org',
  'type': 'norm'
},
{
  'code': 'personnel',
  'name': '人事',
  'dataAuthorityConfig': 'org',
  'type': 'norm'
},
{
  'code': 'section_chief',
  'name': '科长',
  'dataAuthorityConfig': 'org',
  'type': 'norm'
}]


// 顺序 父子关系 按数据顺序及 children 确定
// clientType 默认为 web
// 有url的 type 默认为 sys_page,没有的默认为 menu ，其他情况需要进行指定
// code 默认为 url 若相同url 对应多个菜单 则code需要处理
// 若没有 url 则code自定义，避免重复就可以
export const menus = [{
  'icon': 'icon-a-zu1444',
  'name': '首页',
  'url': 'person.index',
  'roles': ['worker', 'dean', 'personnel']
},
{
  'code': 'zhinengfenxi',
  'icon': 'icon-a-zu1433',
  'name': '智能分析',
  'roles': ['dean', 'personnel', 'section_chief'],
  'children': [{
    'name': '智能分析',
    'url': 'worker.count',
    'roles': ['dean', 'personnel', 'section_chief']
  },
  {
    'name': '员工信息完善度',
    'url': 'worker.inforate',
    'roles': ['dean', 'personnel', 'section_chief']
  }]
},
{
  'code': 'org_manage',
  'icon': 'icon-jigoushezhi',
  'name': '机构管理',
  'roles': ['dean', 'personnel', 'section_chief'],
  'children': [{
    'name': '院机构基础配置',
    'url': 'base.orginfo',
    'roles': ['personnel']
  },
  {
    'name': '组织架构设置',
    'url': 'base.org',
    'roles': [{
      'dean': ['get', 'tree', 'goWorker', 'goCount']
    },
    'personnel',
    {
      'section_chief': ['get', 'tree', 'goWorker', 'goCount']
    }]
  },
  {
    'name': '职务管理',
    'url': 'base.position',
    'roles': [{
      'dean': ['get']
    }, 'personnel']
  },
  {
    'name': '公告管理',
    'url': 'base.placard',
    'roles': [{
      'dean': ['get']
    }, 'personnel']
  }]
},
{
  'icon': 'icon-a-zujian215',
  'code': 'worker_center',
  'roles': ['worker', 'dean', 'personnel', 'section_chief'],
  'name': '员工中心',
  'children': [{
    'code': 'yuangongxinguanli',
    'name': '员工信息管理',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'children': [{
      'name': '员工信息管理',
      'url': 'worker.worker',
      'roles': [{
        'worker': ['get', 'history', 'output', 'allinfo']
      },
      {
        'dean': ['get', 'history', 'output', 'allinfo']
      },
      {
        'section_chief': ['get', 'history', 'output', 'allinfo']
      }, 'personnel']
    },
    {
      'name': '在职员工信息管理',
      'url': 'worker.worker_in',
      'roles': [{
        'dean': ['get', 'history', 'output', 'allinfo']
      },
      {
        'section_chief': ['get', 'history', 'output', 'allinfo']
      }, 'personnel']
    },
    {
      'name': '非在职员工信息管理',
      'url': 'worker.worker_out',
      'roles': [{
        'dean': ['get', 'history', 'output', 'allinfo']
      },
      {
        'section_chief': ['get', 'history', 'output', 'allinfo']
      }, 'personnel']
    }]
  },
  {
    'code': 'pun_rew',
    'name': '奖惩管理',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'children': [{
      'name': '奖励信息管理',
      'url': 'worker.rew',
      'roles': [{
        'worker': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    },
    {
      'name': '处分信息管理',
      'url': 'worker.pun',
      'roles': [{
        'worker': ['get']
      },
      {
        'dean': ['get']
      },
      {
        'section_chief': ['get']
      }, 'personnel']
    }],
  },
  {
    'name': '个人经历',
    'code': 'worker_history',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'children': [{
      'name': '个人工作历史记录',
      'url': 'worker.his_work',
      'roles': [{
        'worker': ['get']
      },
      {
        'dean': ['get']
      },
      {
        'section_chief': ['get']
      }, 'personnel']
    },
    {
      'name': '教育经历',
      'url': 'worker.worker_edu',
      'roles': [{
        'worker': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    },
    {
      'name': '个人上岗历史记录',
      'url': 'worker.worker_job_title',
      'roles': [{
        'worker': ['get']
      },
      {
        'dean': ['get']
      },
      {
        'section_chief': ['get']
      }, 'personnel']
    }]
  },
  {
    'name': '培训与进修管理',
    'url': 'worker.train',
    'roles': [{
      'worker': ['get']
    },
    {
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  },
  {
    'name': '授权管理',
    'url': 'worker.worker_auth',
    'roles': ['personnel']
  }]
},
{
  'icon': 'icon-a-zu1434',
  'code': 'hetongyuzhengjian',
  'roles': ['worker', 'dean', 'personnel', 'section_chief'],
  'name': '证件与合同',
  'children': [{
    'name': '证书管理',
    'code': 'worker_certificate',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'type': 'menu',
    'children': [{
      'name': '执业证书管理',
      'url': 'worker.worker_certificate_doctor_pra',
      'roles': [{
        'worker': ['get','data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    },
    {
      'name': '专业技术职务聘任证书管理',
      'url': 'worker.worker_certificate_pro_hire',
      'roles': [{
        'worker': ['get' ,'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    },
    {
      'name': '专业技术资格证书管理',
      'url': 'worker.worker_certificate',
      'roles': [{
        'worker': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    },
    {
      'name': '个人职业资格证书管理',
      'url': 'worker.worker_certificate_doctor_qua',
      'roles': [{
        'worker': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    },
    {
      'name': '其他证书管理',
      'url': 'worker.worker_certificate_other',
      'roles': [{
        'worker': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    }],
  },
  {
    'name': '合同管理',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'code': 'agreement_manage',
    'type': 'menu',
    'children': [{
      'name': '劳动合同管理',
      'url': 'worker.agreement',
      'roles': [{
        'worker': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'dean': ['get', 'data_file', 'getDataFile', 'downDataFile']
      },
      {
        'section_chief': ['get', 'data_file', 'getDataFile', 'downDataFile']
      }, 'personnel']
    }]
  },
  {
    'name': '证件管理',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'code': 'zhengjian_manage',
    'children': [{
      'name': '身份证管理',
      'url': 'worker.worker_idcard',
      'roles': [{
        'worker': ['get', 'idcard_file']
      },
      {
        'dean': ['get', 'idcard_file']
      },
      {
        'section_chief': ['get', 'idcard_file']
      }, 'personnel']
    }]
  }],
},
{
  'icon': 'icon-a-zu1435',
  'name': '薪资管理',
  'url': 'worker.wage',
  'roles': ['finance']
},
{
  'icon': 'icon-a-zu1436',
  'code': 'warning_center',
  'roles': ['worker', 'dean', 'personnel', 'section_chief'],
  'name': '预警中心',
  'children': [{
    'name': '岗位晋升预警',
    'url': 'worker.job_title_queue_warning',
    'roles': [{
      'worker': ['get']
    },
    {
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  },
  {
    'name': '过期预警',
    'url': 'worker.agreement_warning',
    'roles': [{
      'worker': ['get']
    },
    {
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  },
  {
    'name': '转正预警',
    'url': 'worker.agreement_turn_warning',
    'roles': [{
      'worker': ['get']
    },
    {
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  }]
},
{
  'icon': 'icon-a-4566',
  'code': 'appraisal_center',
  'roles': ['worker', 'dean', 'personnel', 'section_chief'],
  'name': '考评中心',
  'children': [{
    'name': '创建评价与考核活动',
    'roles': ['dean', 'personnel', 'section_chief'],
    'url': 'appraisal.manage'
  },
  {
    'name': '我的评价与考核活动',
    'roles': ['worker', 'dean'],
    'url': 'appraisal.my'
  }]
},
{
  'icon': 'icon-a-zu1365',
  'code': 'zhichenggangw',
  'roles': ['worker', 'dean', 'personnel', 'section_chief'],
  'name': '职称岗位',
  'children': [{
    'name': '职称聘任排队情况',
    'roles': ['dean', 'personnel'],
    'url': 'worker.job_title_queue'
  },
  {
    'name': '医院职称聘任情况',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'url': 'worker.worker_job_current'
  },
  {
    'name': '医院岗位设置管理',
    'roles': [{
      'dean': ['get']
    }, 'personnel'],
    'url': 'base.job_title'
  }]
},
{
  'icon': 'icon-a-zu1438',
  'code': 'ask_leave_manage',
  'roles': ['worker', 'dean', 'personnel', 'section_chief'],
  'name': '请假管理',
  'children': [{
    'name': '我的请假',
    'roles': ['worker'],
    'url': 'worker.my_ask_leave'
  },
  {
    'name': '请假历史记录',
    'roles': ['worker', 'dean', 'personnel', 'section_chief'],
    'url': 'worker.ask_leave'
  }]
},
{
  'icon': 'icon-a-zu1441',
  'code': 'activity_record',
  'roles': ['personnel'],
  'name': '活动记录',
  'children': [{
    'name': '辖区业务指导记录',
    'roles': ['personnel'],
    'url': 'activity.bus'
  },
  {
    'name': '辖区健康教育活动记录',
    'roles': ['personnel'],
    'url': 'activity.edu'
  },
  {
    'name': '辖区妇幼保健人员培训记录',
    'roles': ['personnel'],
    'url': 'activity.train'
  }],
},
{
  'icon': 'icon-a-zu1440',
  'code': 'apply_check',
  'roles': ['dean', 'personnel', 'section_chief'],
  'name': '审批管理',
  'children': [{
    'name': '员工信息变更审批',
    'roles': ['personnel'],
    'url': 'worker.worker_edit'
  },
  {
    'name': '请假审批人分配',
    'roles': ['personnel'],
    'url': 'worker.ask_leave_sp'
  },
  {
    'name': '我的请假审批',
    'roles': ['dean', 'personnel', 'section_chief'],
    'url': 'worker.my_ask_leave_sp'
  }]
},
{
  'icon': 'icon-a-zu1440',
  'code': 'doc_manage',
  'name': '公文管理',
  'children': [{
    'name': '公文管理',
    'url': 'doc.manage'
  },
  {
    'name': '公文处理',
    'roles': ['worker'],
    'url': 'doc.handle'
  }]
},
{
  'icon': 'icon-a-zu1447',
  'code': 'data_manage',
  'roles': ['dean', 'personnel', 'section_chief'],
  'name': '数据管理',
  'children': [{
    'name': '机构等级',
    'url': 'base.org_type',
    'roles': [{
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  },
  {
    'name': '专业技术资格证书管理',
    'url': 'base.certificate',
    'roles': [{
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  },
  {
    'name': '职称岗位设置',
    'url': 'base.job_title',
    'roles': [{
      'dean': ['get']
    },
    {
      'section_chief': ['get']
    }, 'personnel']
  }]
},
{
  'icon': 'icon-a-zu1446',
  'roles': ['dean', 'personnel'],
  'name': '权限管理',
  'code': 'authority',
  'children': [{
    'name': '用户管理',
    'url': 'authority.user',
    'roles': [{
      'dean': ['get']
    }, 'personnel']
  },
  {
    'name': '角色管理',
    'url': 'authority.role',
    'roles': [{
      'dean': ['get']
    }, 'personnel']
  }]
},
{
  'code': 'sys_setting',
  'icon': 'icon-a-zu1443',
  'name': '系统设置',
  'children': [{
    'name': '系统配置',
    'url': 'authority.sys_config'
  },
  {
    'name': '菜单管理',
    'url': 'authority.menu'
  },
  {
    'name': '功能管理',
    'url': 'authority.fun'
  },
//   {
//     'name': '查询配置',
//     'url': 'conf.conf_query'
//   },
  {
    'name': '可选项配置',
    'url': 'config.select'
  },
  {
    'name': '操作记录',
    'url': 'authority.operation_log'
  }],
},
{
  'clientType': 'wap',
  'roles': ['worker'],
  'name': '工资明细',
  'url': 'wap.salary'
},
{
  'clientType': 'wap',
  'name': '我的考核',
  'roles': ['worker'],
  'url': 'wap.my'
},
{
  'clientType': 'wap',
  'name': '手机端首页',
  'url': 'wap.menu'
// },
// {
//   'name': '工资管理',
//   'icon': 'icon-a-zu1435',
//   'code': 'performance',
//   'children': [{
//     'name': '财政工资',
//     'code': 'performance_manage',
//     'children': [{
//       'name': '财政工资',
//       'url': 'performance.worker_wage'
//     },
//     {
//       'name': '员工总览',
//       'url': 'performance.worker_month'
//     },
//     {
//       'name': '岗位总览',
//       'url': 'performance.worker_wage_job_title'
//     },
//     {
//       'name': '薪级总览',
//       'url': 'performance.worker_wage_level'
//     },
//     {
//       'name': '补贴工资总览',
//       'url': 'performance.worker_subsidy'
//     }]
//   },
//   {
//     'name': '岗位等级',
//     'url': 'performance.wage_job_title'
//   },
//   {
//     'name': '薪级等级',
//     'url': 'performance.wage_level'
//   },
//   {
//     'name': '职工信息',
//     'url': 'performance.worker'
//   }]
}]
